@layer modules, ui, base;
@layer base {
  .Testimony_main__TEnG9 {
  max-width: 100vw;
}

.Testimony_wrapper__y48pD,
.Testimony_card__WeY24 {
  box-sizing: border-box;
  height: 100%;
}

.Testimony_card__WeY24 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 70vw;
  padding: var(--spacer-200);
  border: solid 2px transparent; /* !important */
  border-radius: var(--radius-200);
  background-clip: padding-box; /* !important */
  background-color: var(--color-grey--025);
}

.Testimony_card__WeY24::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  inset: 0;
  margin: -2px; /* !important */
  border-radius: inherit; /* !important */
  background: var(--color-grey--100);
  /* stylelint-disable-next-line */
  background: linear-gradient(-15deg, rgba(157, 44, 255, 1) 11%, rgba(0, 210, 0, 1) 100%);
}

.Testimony_image__YwAvl {
  width: auto;
  height: var(--spacer-800);
}

.Testimony_text__uGqlT {
  margin: var(--spacer-200) 0;
  font-size: var(--smallBody);
  line-height: var(--smallBodyLineHeight);
}

.Testimony_text__uGqlT p {
  margin: 0;
}

.Testimony_signature__onxDL {
  display: flex;
  align-items: center;
  gap: var(--spacer-200);
}

.Testimony_author__DkXWK {
  width: 56px;
  min-width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: var(--radius-rounded);
}

.Testimony_authorImage__KzfBU {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.Testimony_signature__onxDL::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: var(--spacer-200);
  width: 138px;
  height: 133px;
  background-image: var(--backgroundImage);
  background-size: cover;
}

@media (min-width: 64rem) {
  .Testimony_card__WeY24 {
    min-width: calc(var(--Layout-size) / 2);
    padding: var(--spacer-400);
  }

  .Testimony_text__uGqlT {
    margin: var(--spacer-300) 0;
    font-size: var(--normalBody);
    line-height: var(--normalBodyLineHeight);
  }

  .Testimony_signature__onxDL::after {
    bottom: 0;
    width: 247px;
    height: 239px;
  }
}

}
